<template>
    <div id="root"></div>
</template>

<script>
import { onMounted, defineComponent } from 'vue';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';

export default defineComponent({
    name: 'ZegoCall',
    props: {
        roomID: {
            type: String,
            required: true
        },
        userID: {
            type: String,
            required: true
        },
        userName: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const generateToken = async (userID, roomID) => {
            const response = await fetch(`${process.env.VUE_APP_TOKEN_URL}?userId=${userID}&roomId=${roomID}`);
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const data = await response.json();
            return data.token;
        };

        onMounted(async () => {
            const appID = Number(process.env.VUE_APP_ID);

            const serverToken = await generateToken(props.userID, props.roomID);
            const token = ZegoUIKitPrebuilt.generateKitTokenForProduction(appID, serverToken, props.roomID, props.userID, props.userName);

            const zp = ZegoUIKitPrebuilt.create(token);
            zp.joinRoom({
                container: document.querySelector('#root'),
                scenario: {
                    mode: ZegoUIKitPrebuilt.OneONoneCall,
                },
                showPreJoinView: false,
                branding: {
                    logoURL: "https://pondokhub.com/assets/images/logo_putih.png"
                },
                showRoomTimer: true,
                showLeavingView: false,
                showScreenSharingButton: false,
                maxUsers: 2,
                showTextChat: false,
                showUserList: false

            });
        });

        return {};
    }
});
</script>

<style>
#root {
    width: 100vw;
    height: 100vh;
}
</style>
