<template>
  <ZegoCall :roomID="roomID" :userID="userID" :userName="userName" />
</template>

<script>
import ZegoCall from './components/ZegoPB.vue';
import CryptoJS from 'crypto-js';


export default {
  name: 'App',
  components: {
    ZegoCall
  },
  data() {
    return {
      userID: "",
      userName: "",
      roomID: "",
      token: ""
    };
  },
  created() {
    const queryParams = new URLSearchParams(window.location.search);
    this.userID = queryParams.get('userID') || '';
    this.userName = queryParams.get('userName') || '';
    this.roomID = queryParams.get('roomID') || '';
    this.token = queryParams.get('token') || '';

    const secretKey = "Lk9uBZ5!2q4@Ks&$YnG"
    
    function urlSafeBase64Decode(input) {
        // Replace URL-safe characters with standard Base64 characters
        let base64 = input.replace(/-/g, '+').replace(/_/g, '/');
        // Add padding if necessary
        const padding = base64.length % 4;
        if (padding) {
            base64 += '='.repeat(4 - padding);
        }
        return base64;
    }

    function decryptByDES(encryptedBase64) {
        const key = "Lk9uBZ5!2q4@Ks&$YnG";
        const keyHex = CryptoJS.enc.Utf8.parse(key);

        // Decode from URL-safe Base64 to standard Base64 then to WordArray
        const base64 = urlSafeBase64Decode(encryptedBase64);
        const ciphertext = CryptoJS.enc.Base64.parse(base64);

        // Decrypt using DES
        const decrypted = CryptoJS.DES.decrypt(
            {
                ciphertext: ciphertext
            },
            keyHex,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        // Convert decrypted WordArray to string
        const decryptedMessage = decrypted.toString(CryptoJS.enc.Utf8);
        return decryptedMessage;
    }


    if (this.token) {
      const decryptedData = JSON.parse(decryptByDES(this.token, secretKey));
      this.userID = decryptedData.userID
      this.userName = decryptedData.userName
      this.roomID = decryptedData.roomID
      
    } else if (!this.userID || !this.userName || !this.roomID) {
      alert('All query parameters (userID, userName, roomID) are required.');
      // You might want to redirect the user or handle this case appropriately
    }    
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 10px; */
}
</style>